/* Popup container styling */
.popupContainer{
    position: fixed;
    top: 65%;
    left: 60%;
    transform: translate(-50%, -50%);
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    width: 90%;
    max-width: 500px;
    animation: fadeIn 0.3s ease-in-out;
  }
 
  /* Smooth fade-in animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 
/* Styling for the select and textarea elements */
.popupSelect,
.popupTextarea {
    width: 100%;
    margin-top: 15px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 16px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
}
 
.popupSelect:focus,
.popupTextarea:focus {
    border-color: #5c6bc0;
    outline: none;
}
 
  /* Button styling */
.popupContainer button {
    margin-top: 15px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
 
 
.closeBtn {
    background-color: red !important;
    margin-right: 18rem;
}
 
.saveBtn {
    background-color: green;
}
 
  /* Background overlay */
  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    animation: fadeInOverlay 0.3s ease-in-out;
}
 
  /* Fade-in animation for overlay */
  @keyframes fadeInOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 
  /* Responsive styling */
  @media (max-width :768px){
    .popupContainer{
        position: fixed;
        top: 50%;
        left: 65%;
        width: 70%;
      }
     
      .buttonWrap {
        display: flex; /* Use flexbox for alignment */
        justify-content: space-between; /* Ensure space between buttons */
        gap: 10px; /* Add a gap between the buttons */
        margin-top: 15px; /* Adjust spacing above buttons */
    }
 
    .saveBtn,
    .closeBtn {
        width: 45%; /* Adjust button width to fit nicely in one row */
    }
  }
 
 
  @media (max-width: 480px) {
    .popupContainer {
        width: 55%;
        padding: 20px;
    }
 
    .popupSelect,
    .popupTextarea {
        font-size: 14px;
    }
 
    .popupContainer h3 {
        font-size: 18px;
    }
    .buttonWrap {
        display: flex; /* Use flexbox for alignment */
        justify-content: center; /* Ensure space between buttons */
        gap: 10px; /* Add a gap between the buttons */
        flex-direction: column;
    }
 
    /* No need to add margin-left to saveBtn, Flexbox will handle positioning */
    .saveBtn,
    .closeBtn {
        width: 70%; /* Make the buttons smaller and align them side by side */
        margin-left: 2rem;
    }
}
 