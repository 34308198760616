.RegisteredUsercontainer {
    margin-top: 8%;
    margin-left: 23.2%;
    background-color: white;
    border-radius: 10px;
    height: 100%;
    width: 75%;
    padding: 10px;
  }
 
  .RegisteredUser-Table {
    margin-top: 5%;
    margin-left: 2%;
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 96.5%;
  }
 
  .RegisteredUserbutton > button:hover {
    color: white;
  }
 
  .RegisteredUserbutton > button {
    width: auto;
    margin-left: 15px;
    gap: 20px;
    border: none;
    justify-content: space-between;
  }
 
  .RegisteredUserTH {
    text-align: center;
    padding: 14px 20px;
    border-radius: 10px 10px 0 0;
  }
 
  .RegisteredUsertr {
    background-color: #333;
    color: white;
  }
 
  .RegisteredUsertr:nth-child(even) {
    background-color: #dddddd;
  }
 
  .RegisteredUserbutton > button {
    background: none;
    border: none;
  }
 
  .RegisteredUserEditContainer {
    position: absolute;
    width: 50%;
    height: 95%;
    overflow: hidden auto;
    top: 65%;
    left: 60%;
    transform: translate(-50%, -50%);
    background: linear-gradient(100deg, rgb(0, 247, 255), blue);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px black;
  }
 
  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
    .RegisteredUsercontainer {
        margin-left: 20%;
        width: 80%;
    }
  }
 
  @media (max-width: 992px) {
    .RegisteredUsercontainer {
        margin-left: 15%;
        width: 85%;
    }
  }
 
  @media (max-width: 768px) {
    .RegisteredUsercontainer {
        margin-left: 10%;
        width: 90%;
    }
  }
 
  @media (max-width: 576px) {
    .RegisteredUsercontainer {
        margin-left: 5%;
        width: 95%;
    }
  }
 
  @media (max-width: 480px) {
    .RegisteredUsercontainer {
        margin-left: 0;
        width: 100%;
        border-radius: 0;
        overflow: scroll;
    }
  }
 
 