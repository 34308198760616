

.InvoiceListcontainer{
    margin-top:8%;
    margin-left: 22.2%;
    background-color:white;
    border-radius: 10px;
    height: 100%;
    width:80%;
    
    /* display: flex; */
    padding: 10px 10px 20px 10px ;

}
/* .content222{
    flex-direction: column;
    display: flex;
} */
.InvoiceList-Table {
  
  margin-top: 5%;
  margin-left:1% ;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width:  98.5%;
    height: 100%;
    /* justify-content: space-between; */
    /* align-items: center; */
  
  

  }
  .InvoiceListbutton>button:hover{
    color: white;
  }
  .InvoiceListbutton>button{
    width: auto;
    margin-left: 10px;
    gap: 20px;
    justify-content: space-between;
  }
  
  .InvoiceListTH,
  .InvoiceListTH {
    /* border: 1px solid black; */
    text-align: center;
    padding: 14px 20px;
    border-radius: 10px 10px 0 0;
  }
  .InvoiceListtr{
    background-color: #333;
    color: white;
  }
  .InvoiceListtr:nth-child(even) {
    background-color: #dddddd;
  }
  .InvoiceListbutton>button{
    background: none;
    border: none;
    
  }
  /* body {
    font-family: Arial, sans-serif;
    margin: 20px;
} */
/* .input[type="text"] {
    padding: 8px;
    font-size: 16px;
}
.submit {
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
}

   */
  /* body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
} */

/* #fullscreenComponent {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    align-items: center;
    justify-content: center;
    text-align: center;
} */

/* #closeButton {
    margin-top: 20px;
    padding: 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    cursor: pointer;
} */
 @media (max-width:480px){
  .InvoiceListcontainer{
    margin-left: 0;
    width: 100%;
    overflow: scroll;
  }
 }