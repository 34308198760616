/* For View Button */
  .hover-effect-view:hover {
    color: rgb(6, 6, 249); /* Color on hover */
  }
  

/* Added CSS for Modal */
#modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  #modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 20%;
    margin-left: 5rem;
  }

  #modal-content p :hover{
    color: blue;
  }
  
  #modal-button {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  #modal-button button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  #modal-button button:first-child {
    background-color: red;
    color: white;
  }  
   
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
  
    #modal-content {
      width: 34%;
      margin-left: 5rem;
    }
  }
   
  @media (max-width: 480px) {
      
    #modal-content {
      width: 45%;
      margin-left: 2rem;
    }
  }
  
  @media (max-width: 375px) {
  
    #modal-content {
      width: 50%;
      margin-left: 2rem;
    }
  }
  
  @media (max-width: 320px) {
  
    #modal-content {
      width: 60%;
      margin-left: 2rem;
    }
  }