 
/* Styles for Each Image */
.property-image {
  flex: 0 0 calc(33.333% - 10px); /* Default: Show 3 images on desktop */
  height: auto;
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
  transition: transform 0.3s ease; /* Add hover effect */
}
 
.property-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}
 
/* Tablet: Show 2 images at a time */
@media (min-width: 768px) and (max-width: 1023px) {
  .property-image {
    flex: 0 0 calc(50% - 10px); /* 2 images visible */
  }
}
 
/* Mobile: Show 1 image at a time */
@media (max-width: 767px) {
  .property-image {
    flex: 0 0 100%; /* 1 image visible */
  }
}
 
.poopupeye{
  width: 80%;
  height: auto;
  margin: 0 auto;
}
  .close-btn{
    background-color: red;
    color: #fff;
    width: 20px;
    border-radius: 50%;
    font-size: 12px;
    float: right;
    margin-right: 10px;
  }
  .PropertiesUnderEyes-div {
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 0rem;
    width: 100%;
  }
  .scroll-container {
    background-color: none;
    overflow: auto;
    white-space: nowrap;
    padding: 10px;
    /* width: 100%;
    height: 100%; */
  }
 
  .scroll-container img {
    padding: 10px;
    width: 250px; /* Allow the width to adjust based on the container */
    height: 250px; /* Fixed height for the container */
    object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
  }
 
  /* .PropertiesUnderEyes-div-2 {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  } */
  .PropertiesUnderEyes-img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    stroke-width: 1px;
    stroke: #e5e7eb;
    margin-top: 54px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-img {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-div-2 {
    margin-top: 35px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    overflow: hidden auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-2 {
      max-width: 100%;
    }
  }
  .PropertiesUnderEyes-div-3 {
    gap: 20px;
    display: flex;
    overflow: hidden auto;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-3 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .PropertiesUnderEyes-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 28%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-column {
      width: 100%;
    }
  }
  .PropertiesUnderEyes-img-2 {
    /* aspect-ratio: 1.1; */
    /* object-fit: contain; */
    /* object-position: center; */
    width: 100%;
    flex-grow: 1;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-img-2 {
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-column-2 {
    display: flex;
    flex-direction: row;
    line-height: normal;
    width: 43%;
    margin-left: 20px;
    overflow: hidden auto;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-column-2 {
      width: 100%;
    }
  }
  .PropertiesUnderEyes-img-3 {
    /* aspect-ratio: 1.72; */
    /* object-fit: contain; */
    /* object-position: center; */
    width: 100%;
    /* flex-grow: 1; */
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-img-3 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 29%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-column-3 {
      width: 100%;
    }
  }
  .PropertiesUnderEyes-div-4 {
    display: flex;
    flex-direction: column;
    color: #ef4444;
    position: relative;
    overflow: hidden;
    aspect-ratio: 1.15;
    flex-grow: 1;
    justify-content: center;
    align-items: start;
    padding: 141px 60px 50px 107px;
    font: 400 12px/150% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-4 {
      margin-top: 40px;
      padding: 40px 20px 0 30px;
    }
  }
  .PropertiesUnderEyes-img-4 {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .PropertiesUnderEyes-div-5 {
    position: relative;
  }
  .PropertiesUnderEyes-div-6 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
   
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-6 {
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-div-7 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-7 {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  .PropertiesUnderEyes-div-8 {
    color: #000;
    align-self: stretch;
    flex-grow: 1;
    margin-top: 20px;
    white-space: nowrap;
    font: 700 34px/111% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-8 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-9 {
    display: flex;
    margin-top: 4px;
    gap: 0px;
  }
  .PropertiesUnderEyes-img-5 {
    aspect-ratio: 4.17;
    object-fit: contain;
    object-position: center;
    width: 105px;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
  .PropertiesUnderEyes-div-10 {
    color: var(--Neutral-900, #111827);
    align-self: start;
    margin-top: 10px;
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px/111% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-10 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-8 {
    font: 700 16px/111% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  .PropertiesUnderEyes-div-11 {
    margin-right: 0px;
    margin-top: 15px;
    display: flex;
}
.PropertiesUnderEyes-div-7 {
  flex-wrap: nowrap;
  align-items: normal;
}
  .PropertiesUnderEyes-img-6 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  .PropertiesUnderEyes-div-12 {
    color: #f15a29;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 400 14px/111% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-12 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-13 {
    align-self: start;
    display: flex;
    margin-top: 24px;
    gap: 14px;
  }
  .PropertiesUnderEyes-img-7 {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 28px;
  }
  .PropertiesUnderEyes-div-14 {
    color: var(--Neutral-900, #111827);
    align-self: start;
    margin-top: 11px;
    flex-grow: 1;
    font: 400 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-15 {
    align-self: start;
    display: flex;
    margin-top: 26px;
    gap: 14px;
  }
  .PropertiesUnderEyes-img-8 {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 25px;
  }
  .PropertiesUnderEyes-div-16 {
    color: var(--Neutral-900, #111827);
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px/111% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-16 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-17 {
    display: flex;
    margin-top: 22px;
    width: 410px;
    max-width: 100%;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
  }
  .PropertiesUnderEyes-div-18 {
    display: flex;
    gap: 9px;
  }
  .PropertiesUnderEyes-img-9 {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 25px;
  }
  .PropertiesUnderEyes-div-19 {
    color: var(--Neutral-900, #111827);
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-19 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-20 {
    display: flex;
    gap: 13px;
  }
  .PropertiesUnderEyes-img-10 {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 25px;
  }
  .PropertiesUnderEyes-div-21 {
    color: var(--Neutral-900, #111827);
    margin: auto 0;
    font: 400 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-22 {
    display: flex;
    gap: 14px;
  }
  .PropertiesUnderEyes-img-11 {
    aspect-ratio: 1.09;
    object-fit: contain;
    object-position: center;
    width: 26px;
  }
  .PropertiesUnderEyes-div-23 {
    color: var(--Neutral-900, #111827);
    font: 400 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-24 {
    align-self: start;
    display: flex;
    gap: 11px;
    margin: 36px 0 0 31px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-24 {
      margin-left: 10px;
    }
  }
  .PropertiesUnderEyes-img-12 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 34px;
    border-radius: 50%;
  }
  .PropertiesUnderEyes-div-25 {
    color: #000;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 700 16px/111% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
     
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-25 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-img-13 {
    object-fit: contain;
    object-position: center;
    width: 800px;
    stroke-width: 1px;
    stroke: var(--Neutral-200, #e5e7eb);
    align-self: end;
    margin-top: 22px;
    max-width: 100%;
  }
  .PropertiesUnderEyes-div-26 {
    color: #000;
    align-self: start;
    margin: 40px 0 0 0;
    font: 600 18px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-26 {
      margin-left: 10px;
    }
  }
  .PropertiesUnderEyes-div-27 {
    color: var(--Neutral-500, #6b7280);
    align-self: flex-start;
    margin-top: 26px;
    max-width: 900px;
    font: 400 14px/16px Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-27 {
      max-width: 100%;
    }
  }
  .PropertiesUnderEyes-div-28 {
    color: #000;
    align-self: start;
    white-space: nowrap;
    margin: 40px 0 0 0;
    font: 600 18px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-28 {
      margin-left: 10px;
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-29 {
    align-self: start;
    display: flex;
    gap: 13px;
    margin: 27px 0 0 29px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-29 {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  .PropertiesUnderEyes-div-30 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: #f3f4f6;
    margin-left: -20px;
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-31 {
    color: var(--Neutral-500, #6b7280);
    white-space: nowrap;
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-31 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-32 {
    color: var(--Neutral-500, #6b7280);
    white-space: nowrap;
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-32 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-33 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    aspect-ratio: 1.38;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-34 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: #f3f4f6;
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-35 {
    color: #000;
    align-self: start;
    margin: 29px 0 0 0;
    font: 600 18px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-35 {
      margin-left: 10px;
    }
  }
  .PropertiesUnderEyes-div-36 {
    align-self:flex-start;
    display: flex;
    margin: 27px 0 0 29px;
    gap: 8px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-36 {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  .PropertiesUnderEyes-div-37 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    aspect-ratio: 1.27;
    justify-content: center;
    padding: 19px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-38 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-39 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-40 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    aspect-ratio: 1.48;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-41 {
    color: var(--Neutral-500, #6b7280);
    white-space: nowrap;
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-41 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-42 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-43 {
    color: var(--Neutral-500, #6b7280);
    white-space: nowrap;
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-43 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-44 {
    align-self: start;
    display: flex;
    gap: 8px;
    margin: 12px 0 0 31px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-44 {
      margin-left: 10px;
    }
  }
  .PropertiesUnderEyes-div-45 {
    color: var(--Neutral-500, #6b7280);
    white-space: nowrap;
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-45 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-46 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    aspect-ratio: 1.27;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-47 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    aspect-ratio: 1.06;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-48 {
    color: #000;
    align-self: start;
    margin: 29px 0 0 31px;
    font: 600 18px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-48 {
      margin-left: 10px;
    }
  }
  .PropertiesUnderEyes-div-49 {
    align-self: start;
    display: flex;
    gap: 11px;
    margin: 27px 0 0 31px;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-49 {
      margin-left: 10px;
    }
  }
  .PropertiesUnderEyes-div-50 {
    color: var(--Neutral-500, #6b7280);
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    aspect-ratio: 1.35;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-51 {
    color: var(--Neutral-500, #6b7280);
    white-space: nowrap;
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-51 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-52 {
    color: var(--Neutral-500, #6b7280);
    white-space: nowrap;
    border-radius: 10px;
    background-color: var(--Neutral-100, #f3f4f6);
    flex-grow: 1;
    justify-content: center;
    padding: 15px 17px;
    font: 600 14px Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-52 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-div-53 {
    align-self: center;
    display: flex;
    margin-top: 50px;
    /* height: 1px; */
    width: 777px;
    max-width: 100%;
    /* align-items: start; */
    justify-content: space-between;
    /* gap: 200px; */
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-53 {
      flex-wrap: wrap;
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-div-54 {
    color: var(--Neutral-Gray-900, #0f172a);
    letter-spacing: -0.36px;
    margin-top: 11px;
    font: 600 18px/128% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  .PropertiesUnderEyes-div-55 {
    display: flex;
    gap: 2px;
  }
  .PropertiesUnderEyes-div-56 {
    color: var(--Neutral-Gray-900, #0f172a);
    flex-grow: 1;
    font: 600 16px/150% Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  .PropertiesUnderEyes-img-14 {
    aspect-ratio: 0.93;
    object-fit: contain;
    object-position: center;
    width: 14px;
    align-self: start;
    /* margin-top: -10px; */
  }
  .PropertiesUnderEyes-img-15 {
    object-fit: contain;
    object-position: center;
    width: 773px;
    stroke-width: 1px;
    stroke: var(--Neutral-Gray-200, #e2e8f0);
    align-self: center;
    margin-top: 67px;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-img-15 {
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-div-57 {
    align-self: end;
    display: flex;
    width: 749px;
    max-width: 100%;
    justify-content: space-between;
    gap: 20px;
    margin: 25px 23px 0 0;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-57 {
      margin-right: 10px;
      flex-wrap: wrap;
    }
  }
  .PropertiesUnderEyes-div-58 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-weight: 600;
    /* margin-top: -5%; */
    margin-left: -9%;
  }
  .PropertiesUnderEyes-img-16 {
    aspect-ratio: 0.93;
    object-fit: contain;
    object-position: center;
    width: 65px;
    border-radius: 50%;
    align-self: stretch;
  }
  .PropertiesUnderEyes-div-59 {
    color:  #0f172a;
    /* letter-spacing: -0.36px; */
    align-self: stretch;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 18px/128% Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-60 {
    color: var(--Neutral-Gray-500, #64748b);
    letter-spacing: -0.28px;
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 14px/143% Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-60 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-img-17 {
    aspect-ratio: 4.55;
    object-fit: contain;
    object-position: center;
    width: 157px;
    align-items: flex-start;
    align-self: start;
    margin-top: 10px;
    max-width: 100%;
  }
  .PropertiesUnderEyes-div-61 {
    color: var(--Neutral-Gray-500, #64748b);
    align-self: center;
    margin-top: 43px;
    font: 400 16px/24px Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-61 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-img-18 {
    object-fit: contain;
    object-position: center;
    width: 769px;
    stroke-width: 1px;
    stroke: var(--Neutral-Gray-200, #e2e8f0);
    align-self: center;
    margin-top: 54px;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-img-18 {
      margin-top: 40px;
    }
  }
  .PropertiesUnderEyes-div-62 {
    /* align-self: end; */
    display: flex;
    width: 749px;
    max-width: 100%;
    justify-content: space-between;
    gap: 20px;
    /* margin-left: -1% ; */
    margin-top: 5%;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-62 {
      margin-right: 10px;
      flex-wrap: wrap;
    }
  }
  .PropertiesUnderEyes-div-63 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-weight: 600;
  }
  .PropertiesUnderEyes-img-19 {
    aspect-ratio: 0.93;
    object-fit: contain;
    object-position: center;
    width: 65px;
    border-radius: 50%;
    align-self: stretch;
  }
  .PropertiesUnderEyes-div-64 {
    color: var(--Neutral-Gray-900, #0f172a);
    letter-spacing: -0.36px;
    align-self: stretch;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 18px/128% Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .PropertiesUnderEyes-div-65 {
    color: var(--Neutral-Gray-500, #64748b);
    letter-spacing: -0.28px;
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 14px/143% Plus Jakarta Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-65 {
      white-space: initial;
    }
  }
  .PropertiesUnderEyes-img-20 {
    aspect-ratio: 4.76;
    object-fit: contain;
    object-position: center;
    width: 157px;
    /* align-items: flex-start; */
    max-width: 100%;
    margin: auto 0;
    margin-left:40% ;
  }
  .PropertiesUnderEyes-div-66 {
    color: var(--Neutral-Gray-500, #64748b);
    align-self: center;
    margin-top: 44px;
    font: 400 16px/24px Plus Jakarta Sans, -apple-system, Roboto,
      Helvetica, sans-serif;
  }
  @media (max-width: 991px) {
    .PropertiesUnderEyes-div-66 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
 
  /* body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
   */
   .PropertiesUnderEyesimage-row {
    display: flex;
  }
 
  .PropertiesUnderEyesimage {
    width: 200px; /* Adjust the width as needed */
    height: 200px;
    margin-right: 10px; /* Adjust the margin between images as needed */
    overflow: hidden auto;
    padding: 5px;
  }
 
 
  .PropertiesUnderEyesimage {
    width: 195px; /* Adjust the width as needed */
    height: 200px;
    margin-right: 10px; /* Adjust the margin between images as needed */
    cursor: pointer;
  }
 
  .PropertiesUnderEyesimage-modal {
    position: fixed;
    top: 7%;
    left: 15%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 1; */
  }
 
  .PropertiesUnderEyesmodal-image {
    margin-left:-100px;
    max-width: 80%;
    max-height: 80%;
    cursor: pointer;
  }
  .app-container {
    text-align: center;
    padding: 20px;
  }
 
  .pdf-viewer-container {
    max-width: 800px;
    margin: 0 auto;
  }
 
  @media (max-width:480px) {
    .PropertiesUnderEyes-div{
      margin-left: 0;
      width: 100%;
      margin-top: 0rem;
    }
 
    .scroll-container img {
      padding: 10px;
      width: 50%; /* Allow the width to adjust based on the container */
      height: 100px; /* Fixed height for the container */
      object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
    }
    .PropertiesUnderEyes-div-8 {
      font: 700 16px/111% Plus Jakarta Sans, -apple-system, Roboto,
        Helvetica, sans-serif;
    }
    .PropertiesUnderEyes-div-11 {
      margin-right: 0px;
      margin-top: 15px;
  }
  .PropertiesUnderEyes-div-7 {
    flex-wrap: nowrap;
    align-items: normal;
}
   
  }
 
  .owner-details {
    margin-top: 20px;
  }
 
  .card-images {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
 
  .card-image {
    width: 300px;
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  .card-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }  
  .card-image:hover {
    transform: scale(1.1);
    border-color: #007BFF;
  }
 
  .download-link {
    text-decoration: none;
  }
 