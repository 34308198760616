.page-wrapper{
    /* margin-top: px; */
    /* background-color: aqua; */
    height: 100%;
}
.card.board1.fill:hover{
    -webkit-text-fill-color: blue;
}
@media (max-width: 480px){
    .page-wrapper{
    width: 100%;
    }
}
 