.Propertyownerscontainer {
  margin-top: 8%;
  margin-left: 23.2%;
  background-color: white;
  border-radius: 10px;
  height: 100%;
  width: 75%;
  padding: 10px;
}
.content222 {
  display: flex;
  flex-direction: column;
}
 
.Propertyowners-Table {
  margin-top: 5%;
  margin-left: 2%;
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 96.5%;
}
 
.Propertyownersbutton > button:hover {
  color: white;
}
 
.Propertyownersbutton > button {
  width: auto;
  margin-left: 15px;
  gap: 20px;
  border: none;
  justify-content: space-between;
}
 
.PropertyownersTH,
.PropertyownersTH {
  text-align: left;
  padding: 14px 20px;
  border-radius: 10px 10px 0 0;
}
 
.PropertyownersTH button {
  border: none;
  background: none;
}
 
.Propertyownerstr {
  background-color: #333;
  color: white;
}
 
.Propertyownerstr:nth-child(even) {
  background-color: #dddddd;
}
 
.Propertyownersbutton > button {
  background: none;
  border: none;
}
 
.PropertyUserEditContainer {
  position: absolute;
  width: 50%;
  height: 95%;
  overflow: hidden auto;
  top: 65%;
  left: 60%;
  transform: translate(-50%, -50%);
  background: linear-gradient(100deg, rgb(0, 247, 255), blue);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px black;
}

/* Added CSS for Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 20%;
  margin-left: 5rem;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: red;
  color: white;
}

.modal-buttons button:last-child {
  background-color: gray;
  color: white;
}

 
/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .Propertyownerscontainer {
      margin-left: 20%;
      width: 80%;
  }
}
 
@media (max-width: 992px) {
  .Propertyownerscontainer {
      margin-left: 15%;
      width: 85%;
  }
}
 
@media (max-width: 768px) {
  .Propertyownerscontainer {
      margin-left: 10%;
      width: 90%;
  }

  .modal-content {
    width: 34%;
    margin-left: 5rem;
  }
}
 
@media (max-width: 576px) {
  .Propertyownerscontainer {
      margin-left: 5%;
      width: 95%;
  }
}
 
@media (max-width: 480px) {
  .Propertyownerscontainer {
      margin-left: 0;
      width: 100%;
      border-radius: 0;
      overflow: scroll;
  }

  .modal-content {
    width: 45%;
    margin-left: 2rem;
  }
}

@media (max-width: 375px) {

  .modal-content {
    width: 50%;
    margin-left: 2rem;
  }
}

@media (max-width: 320px) {

  .modal-content {
    width: 60%;
    margin-left: 2rem;
  }
}
 
 
 