/* Main container */
.BookingDetailscontainer {
  margin-top: 8%;
  margin-left: 23.2%;
  background-color: white;
  border-radius: 10px;
  height: 100%;
  width: 75%;
  padding: 10px 10px 20px 10px;
  overflow-x: auto;
}

/* Adjust container width and margin for smaller screens */
@media (max-width: 768px) {
.BookingDetailscontainer {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
}

@media (max-width: 480px) {
.BookingDetailscontainer {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
}

/* Table */
.BookingDetails-Table {
  margin-top: 5%;
  margin-left: 2%;
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 96.5%;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
.BookingDetails-Table {
  width: 100%;
  margin-left: 0;
}
}

/* Table Header and Row adjustments for smaller screens */
.BookingDetailsTH, .BookingDetailsTH {
  text-align: left;
  padding: 14px 20px;
  font-size: 14px;
  border-radius: 10px 10px 0 0;
}

@media (max-width: 480px) {
.BookingDetailsTH, .BookingDetailsTH {
  font-size: 12px;
  padding: 5px;
}
}

/* Table Row adjustments for small screens */
.BookingDetailstr {
  background-color: #333;
  color: white;
}

/* Status button styling */
.statusButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.statusButton:hover {
  background-color: #218838;
}

/* Pagination container */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #f1f1f1;
  border: none;
  color: #333;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #ddd;
}

.pagination-button:disabled {
  background-color: #eaeaea;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #000a01;
  color: white;
}

/* Mobile Pagination adjustments */
@media (max-width: 480px) {
.pagination-button {
  padding: 6px 12px;
  font-size: 12px;
}
}
