/* Cursor.css */
.cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    /* background-image: url(.././../../public/assets/img/Group.png);width: 40px;height: 32px; */
    /* background: -webkit-linear-gradient(20deg, #1BFFFF  , blue); */
    border-radius: 50%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff; /* Customize the text color */
    transform: translate(-50%, -50%);
    z-index: 9999;
    user-select: none;
    transition: transform 0.2s ease-out, background-color 0.2s ease-out;
  }
 