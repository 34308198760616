 
.avatar-img{
  margin-left: 5px;
  }
   
  /* For the form */
  .form-control {
    background: linear-gradient(#2E3192, #1BFFFF);
  }
   
  /* Logo Styling */
  .logo1 {
    width: 100%; /* Make it more responsive */
    height: auto; /* aakashEnsure proper scaling */
  }
   .bellring{
    font-size: 20px;
   }
  /* Header Styling */
  .header {
    background-color: black;
    display: flex;
    justify-content: space-between; /* Ensure space between elements */
    align-items: center; /* Vertically center elements */
    padding: 10px 20px; /* Add padding for spacing */
  }
   
  /* Mobile Menu Button */
  .mobile_btn {
    display: none; /* Hide it by default */
    font-size: 24px; /* Adjust button size */
    color: white; /* Make it stand out */
  }

  

    /* For Seen and Unseen buttons */
.notification-tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.notification-tabs .tab-button {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notification-tabs .tab-button.active {
  background-color: #007bff;
  color: #fff;
}

.notification-tabs .tab-button:not(:last-child) {
  border-right: none; /* Avoid double borders */
}


/* Blinking red dot for unread notifications */
.blinking-dot {
  position: relative;
}

.blinking-dot::after {
  content: "";
  position: absolute;
  top: 4px;
  right: 48px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: blink 2s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
   
  /* For unread notifications */
  .notification-message.unread .noti-title,
  .notification-message.unread .noti-details,
  .notification-message.unread .noti-time {
  color: #000 ; /* Default color for unread */
  }
   
  .notification-message.unread {
  background-color: rgba(144, 203, 238, 0.258); /* Background for unread notifications */
  padding: 0px 5px;
  }
   
  /* For read notifications */
  .notification-message.read .noti-title,
  .notification-message.read .noti-details,
  .notification-message.read .noti-time {
  color: #6c757d; /* Lighter color for read notifications */
  }
   
  .notification-message.read {
  background-color: #fff !important; /* Background for read notifications */
  }
   
  /* Hover effect for notifications */
  .notification-message:hover {
  background-color: #bababa !important;  /* Light gray background on hover */
  }

  .no-notifications{
    text-align: center;
  }


/* For the notification dropdown with scrollbar */
.noti-content {
  max-height: 300px; /* Set a specific max height for the dropdown */
  width: 350px;
  overflow-y: auto; /* Add vertical scrollbar */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding-right: 10px; /* Add padding for scrollbar space */
  z-index: 9999; /* Ensure it's on top */
}

/* Ensure the parent container allows for proper display */
.notifications {
  max-width: 400px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: relative; /* Make sure it can contain the .noti-content */
}
  
.noti-content::-webkit-scrollbar {
  width: 8px;
}

.noti-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.noti-content::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.noti-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Adjust the dropdown container to ensure proper alignment */
.notifications {
  max-width: 400px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

  /* Media Queries for smaller screens */
  @media (max-width: 1200px) {
  .mobile_btn {
    display: inline-block; /* Show on mobile */
    position: absolute; /* Allow positioning */
    left: 20px; /* Move it to the left */
    top: 50%; /* Vertically center */
    transform: translateY(-50%); /* Adjust for proper centering */
    background-color: white;
    color: black;
    text-decoration: black;
   
  }
  }
  /* Media Queries for smaller screens */
  @media (max-width: 992px) {
    .mobile_btn {
      display: inline-block; /* Show on mobile */
    }
  }
  @media (max-width: 768px) {
    .mobile_btn {
      display: inline-block; /* Show on mobile */
    }
  }
  @media (max-width: 576px) {
    .mobile_btn {
      display: inline-block; /* Show on mobile */
    }
   
    .header {
      padding: 10px; /* Adjust padding for smaller screens */
    }
   
    .logo1 {
      width: 60%; /* Adjust logo width */
    }
  }
   
  @media (max-width: 480px) {
    .mobile_btn {
      display: inline-block; /* Show on mobile */
    }
   
    .logo1 {
      text-align: left;
      width: 50%;
      display: block; /* Change to block for smaller screens */
    }
  }
  
/* Bell icon animation */
 
.bell-animate {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: tada;
  animation-name: tada
}

@-webkit-keyframes tada {
  0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }

  10%,
  20% {
      -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
  }

  30%,
  50%,
  70%,
  90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
  }

  40%,
  60%,
  80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
  }

  100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }
}

@keyframes tada {
  0% {
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }

  10%,
  20% {
      -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      -ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
  }

  30%,
  50%,
  70%,
  90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
  }

  40%,
  60%,
  80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
  }

  100% {
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }
}


   
   
   
   