/* Highlighted text styling */
#highlight {
  font-weight: bold;
  padding: 2px 3px;
  border-radius: 4px;
  font-weight: bolder;
}
 
/* Spacing and styles for query items */
#queryItem {
  margin-bottom: 20px; /* Adds spacing between query items */
  border: 1px solid #ccc; /* Optional: Adds border to each query */
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
 
#queryEmail {
  margin-top: 10px; /* Adds spacing between query text and email */
  font-size: 14px;
  color: #555;
  font-weight: bold;
  font-style: italic;
}
 
#queryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 
/* Overall container styles */
#QuieresComponents {
  margin-top: 8%;
  margin-left: auto;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
}
 
/* Navigation bar styles */
#QuieresComponentsNav {
  background-color: #333;
  overflow: hidden;
  width: 100%;
  border-radius: 10px 10px 0 0;
  margin-bottom: 20px;
}
 
#QuieresComponentsNav a {
  display: inline-block;
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  width: 33.33%;
  box-sizing: border-box;
}
 
#QuieresComponentsNav a:hover {
  color: #fff;
}
 
#QuieresComponentsNav .active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}
 
/* Centered content container */
#centeredContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}
 
#centeredContent div {
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
}
 
#centeredContent h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
 
#centeredContent p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}
 
/* Loading message */
#centeredContent p.loading {
  font-size: 16px;
  color: #888;
  text-align: center;
  font-style: italic;
}
 
/* Pagination styles */
#pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
 
#pagination-button {
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid #0a0a0a;
  background-color: white;
  color: #020303;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
 
 
#pagination-button:hover {
  background-color: #7c7c7c;
  color: white;
}
 
#pagination-button.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}
 
#pagination-button:disabled {
  background-color: #e9e9e9;
  color: #aaa;
  cursor: not-allowed;
}
 
/* Responsive styles for medium screens */
@media (max-width: 768px) {
  #QuieresComponents {
    margin-top: 8%;
    margin-left: 5%;
    width: 90%;
  }
 
  #QuieresComponentsNav a {
    padding: 10px;
    font-size: 14px;
    width: 100%; /* Stacked layout for navigation links */
    text-align: left;
  }
 
  #centeredContent h4 {
    font-size: 16px;
  }
 
  #centeredContent p {
    font-size: 14px;
  }
 
  #queryEmail {
    font-size: 12px;
  }
}
 
/* Responsive styles for small screens */
@media (max-width: 480px) {
  #QuieresComponents {
    margin-left: 0;
    width: 100%;
    margin-top: 17%;
    padding: 15px;
  }
 
  #QuieresComponentsNav {
    flex-direction: row; /* Stack navigation items */
    text-align: center;
  }
 
  #QuieresComponentsNav a {
    padding: 12px;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
 
  #centeredContent h4 {
    font-size: 14px;
  }
 
  #centeredContent p {
    font-size: 12px;
  }
 
  #queryEmail {
    font-size: 10px;
  }
 
  #pagination-container {
    flex-direction: row;
    gap: 5px;
  }
 
  #pagination-button {
    width: 100%;
    font-size: 14px;
  }
  .pagination-button {
    width: 80%;
    font-size: 14px;
  }
}
 
 