.PropertiesUnderReviewcontainer {
  margin-top: 8%;
  margin-left: 23.2%;
  background-color: white;
  border-radius: 10px;
  height: 100%;
  width: 75%;
  padding: 10px 10px 20px 10px;
}
 
.PropertiesUnderReview-Table {
  margin-top: 5%;
  margin-left: 2%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 98.5%;
}
 
.PropertiesUnderReviewbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
 
.PropertiesUnderReviewbutton>button:hover {
  color: white;
}
 
.PropertiesUnderReviewbutton>button {
  background: none;
  border: none;
  cursor: pointer;
}
 
.PropertiesUnderReviewTH,
.PropertiesUnderReviewTH {
  text-align: left;
  padding: 14px 20px;
  border-radius: 10px 10px 0 0;
text-align: center;
  padding-right: 40px;
}
 
.PropertiesUnderReviewTH {
  border-bottom: 1px solid grey;
}
 
.PropertiesUnderReviewtr {
  background-color: #333;
  color: white;
}
 
.PropertiesUnderReviewtr:nth-child(even) {
  background-color: #dddddd;
  text-align: center;
}
 
.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.full-screen-content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  padding: 10px;
  position: absolute;
  width: 60%;
  top: 50px;
  margin-top: 60px;
  margin-left: 260px;
}
 
.close-full-screen {
  position: absolute;
  top: 10px;
  right: 10px; /* Adjust as needed */
  background: none;
  border: none;
  cursor: pointer;
}
 
/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .PropertiesUnderReviewcontainer {
    margin-left: 20%;
    width: 80%;
  }
}
 
@media (max-width: 992px) {
  .PropertiesUnderReviewcontainer {
    margin-left: 15%;
    width: 85%;
  }
}
 
@media (max-width: 768px) {
  .PropertiesUnderReviewcontainer {
    margin-left: 10%;
    width: 90%;
  }
  .full-screen-content {
    width: 80%;
    margin-left: 20px;
  }
}
 
@media (max-width: 576px) {
  .PropertiesUnderReviewcontainer {
    margin-left: 5%;
    width: 95%;
  }
}
 
@media (max-width: 480px) {
  .PropertiesUnderReviewcontainer {
    margin-left: 0;
    width: 100%;
    border-radius: 0;
    overflow: scroll;
  }
  .full-screen-content {
    width: 90%;
    margin-left: 0px;
  }
}
 
 