 
/* Wrapper for the withdrawal request container */
#withdrawal-request-wrapper {
    margin-top:8%;
    min-height: 100vh; /* Full viewport height */
    width: 100%; /* Occupy full width of the parent */
   
}
#withdrawal-request-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* width: 80%; /* Adjust width dynamically based on content */
    max-width: 90%;
    width: auto;
    max-height: 90vh;
    /* Prevent the container from exceeding viewport height */
    overflow: auto;
    /* Add scrolling if content exceeds height */
    /*position: absolute;*/
    /*right: 20px;*/
    margin-top: 2rem;
    margin-left: 20rem;
}
 
/* Title styling */
#withdrawal-request-title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}
 
/* Dropdown Container */
#withdrawal-request-dropdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
 
#withdrawal-request-dropdown {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}
 
#withdrawal-request-dropdown:hover {
    background-color: #f1f1f1;
}
 
/* Highlighted text styling */
#withdrawal-request-highlight {
    font-weight: bold;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}
 
/* Table Styling */
#withdrawal-request-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
 
/* Table Row Styling */
#withdrawal-request-table tr {
    height: auto;
    vertical-align: top;
    word-wrap: break-word;
}
 
/* Cell padding adjustment */
#withdrawal-request-table th,
#withdrawal-request-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    white-space: normal;
    text-align: center;
   
}
 
#withdrawal-request-table th {
    background-color: #333;
    padding: 14px 20px;
    /* border-radius: 10px 10px 0 0;
    font-weight: bold; */
    color: white;
    border-radius: 10px 10px 0 0;
}
 
#withdrawal-request-table tbody tr:hover {
    background-color: #f9f9f9;
}
 
/* Responsive Design */
/* Added Responsiveness Here for the table */
@media (max-width :1400px) {
    #withdrawal-request-container {
        margin-top: 2rem;
        padding: 10px;
        max-width: 90%;
        width: auto;
        margin-left: 20rem;
    }
}
 
 
 
 
@media (max-width :992px) {
    #withdrawal-request-wrapper {
        margin-top:8%;
    min-height: 100vh; /* Full viewport height */
    width: 100%; /* Occupy full width of the parent */
    }
    #withdrawal-request-container {
        padding: 15px;
    }
 
    #withdrawal-request-title {
        font-size: 1.6rem;
    }
 
    #withdrawal-request-highlight {
        font-size: 1.3rem;
    }
 
    #withdrawal-request-table th,
    #withdrawal-request-table td {
        font-size: 0.9rem;
        padding: 8px;
        text-align: center;
    }
 
    #withdrawal-request-dropdown {
        padding: 10px;
    }
 
    #withdrawal-request-container {
        margin-top: 2rem;
        padding: 10px;
        max-width: 90%;
        width: auto;
        margin-left: 15rem;
    }
}
 
/* For tablets */
@media (max-width: 768px) {
 
    #withdrawal-request-wrapper {
        min-height: 100vh; /* Full viewport height */
        width: 100%; /* Occupy full width of the parent */
        background-color: #f0f0f0; /* Optional: Add background color for this section */
    }
    #withdrawal-request-container {
        padding: 15px;
    }
 
    #withdrawal-request-title {
        font-size: 1.6rem;
    }
 
    #withdrawal-request-highlight {
        font-size: 1.3rem;
    }
 
    #withdrawal-request-table th,
    #withdrawal-request-table td {
        font-size: 0.6rem;
        padding: 8px;
        text-align: center;
    }
 
    #withdrawal-request-dropdown {
        padding: 10px;
    }
}
 
 
 
/* For small screens and mobile devices */
@media (max-width: 480px) {
 
    #withdrawal-request-wrapper {
        display: flex; /* Enable flexbox for centering */
        justify-content: center; /* Center horizontally */
        min-height: 100vh; /* Full viewport height */
        width: 100%; /* Occupy full width of the parent */
        background-color: #f0f0f0; /* Optional: Add background color for this section */
    }
 
    #withdrawal-request-container {
        margin-top: 6rem;
        padding: 10px;
        margin-left: 0;
    }
 
    #withdrawal-request-title {
        font-size: 1.4rem;
    }
 
    #withdrawal-request-highlight {
        font-size: 1.1rem;
    }
 
    #withdrawal-request-table th,
    #withdrawal-request-table td {
        font-size: 11px;
       text-align: center;
       font-weight: none;
       padding: 5;
       line-height: 15px;
       width: 30% !important; 
    }
 
    #withdrawal-request-dropdown {
        padding: 8px;
        font-size: 0.9rem;
    }
 
    #withdrawal-request-dropdown-container {
        justify-content: flex-start;
    }
 
    #withdrawal-request-table-container {
        overflow-x: auto;
        margin-bottom: 20px;
    }
}
 
 
 