
.BookingHistoryTD{
  text-align: center;
}
.BookingHistorycontainer{
    margin-top:8%;
    margin-left: 23.2%;
    background-color:white;
    border-radius: 10px;
    height: 100%;
    width:75%;
    overflow: scroll;
    
    /* display: flex; */
    padding: 10px 10px 20px 10px ;

}
/* .content222{
    flex-direction: column;
    display: flex;
} */
.BookingHistory-Table {
  margin-top: 5%;
  margin-left:2% ;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width:  96.5%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .BookingHistorybutton>button:hover{
    color: white;
  }
  .BookingHistorybutton>button{
    width: auto;
    margin-left: 15px;
    gap: 20px;
    justify-content: space-between;
  }
  
  .BookingHistoryTH,
  .BookingHistoryTH {
    /* border: 1px solid black; */
    text-align: center;
    padding: 14px 20px;
    border-radius: 10px 10px 0 0;
  }
  .BookingHistorytr{
    background-color: #333;
    color: white;
  }
  .BookingHistorytr:nth-child(even) {
    background-color: #dddddd;
  }
  .BookingHistorybutton>button{
    background: none;
    border: none;
    
  }
  /* body {
    font-family: Arial, sans-serif;
    margin: 20px;
} */
/* .input[type="text"] {
    padding: 8px;
    font-size: 16px;
}
.submit {
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
}

   */
  /* body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
} */

/* #fullscreenComponent {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    align-items: center;
    justify-content: center;
    text-align: center;
} */

/* #closeButton {
    margin-top: 20px;
    padding: 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    cursor: pointer;
} */
 @media (max-width:480px){
  .BookingHistorycontainer{
    margin-left: 0;
    width: 100%;
    overflow: scroll;
  }
 }

 /* pagination css  */
 .pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #f1f1f1;
  border: none;
  color: #333;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #ddd;
}

.pagination-button:disabled {
  background-color: #eaeaea;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #000500;
  color: white;
}
